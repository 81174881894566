import * as process from "process";

const resetEnv = process.env.RESET_ENV;

const enableDemoTriggers =
  process.env.ENABLE_DEMO_TRIGGERS === "true" && resetEnv !== "production";

const socureSdkKey = process.env.SOCURE_SDK_KEY;

export default {
  enableDemoTriggers,
  socureSdkKey,
  resetEnv,
};
