import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import FormContainer, {
  FieldContainer,
  InputField,
  SubmitButton,
} from "components/form";
import { usePostMockCreateUserMutation } from "store/api/apiSlice";
import { toBase64 } from "utils/file";
import Modal, { ModalApi } from "./Modal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  text-align: left;
`;

interface Props {
  onSuccess?: () => void;
}

const DemoCreateUserModal = (
  { onSuccess }: Props,
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const [postCreateUser] = usePostMockCreateUserMutation();

  const onSubmit = async ({ logo, ...values }) => {
    const logoFile = logo[0];
    const logoBlob = await toBase64(logoFile);
    await postCreateUser({
      logo: { data: logoBlob, type: logoFile.type },
      ...values,
    });
    if (onSuccess) {
      onSuccess();
    }
    ref.current?.dismiss();
  };

  return (
    <Modal ref={ref}>
      <Wrapper>
        <FormContainer onSubmit={onSubmit}>
          <InputField name="firstName" label="First Name" />
          <InputField name="lastName" label="Last Name" />
          <InputField
            name="accounts.primaryBank"
            label="Financial Institution"
          />
          <InputField name="accounts.payroll" label="Payroll Provider" />
          <FieldContainer name="logo" label="Partner Logo">
            {({ inputProps }) => <input {...inputProps} type="file" />}
          </FieldContainer>

          <SubmitButton>Create User</SubmitButton>
        </FormContainer>
      </Wrapper>
    </Modal>
  );
};

export default forwardRef(DemoCreateUserModal);
