import dayjs from "dayjs";
import { shuffle } from "utils/api/array";
import database from "./db";
import { RECURRING_EXPENSES } from "./constants";
import { getRandomMerchant } from "./utils";

const createRecurringExpense = (db: typeof database, expensePartial: any) =>
  db.recurringExpense.create({ ...expensePartial });

const createRentExpense = (db: typeof database, userId: string) => {
  const isBeginningOfMonth = dayjs().date() === 1;
  const lastOccurrenceDate = isBeginningOfMonth
    ? dayjs().subtract(1, "month")
    : dayjs().startOf("month");
  const nextOccurrenceDate = lastOccurrenceDate.add(1, "month");

  createRecurringExpense(db, {
    userId,
    description: "Main Street Property",
    personalFinanceCategoryPrimary: "RENT_AND_UTILITIES",
    personalFinanceCategorySecondary: "RENT_AND_UTILITIES_RENT",
    lastOccurrenceDate,
    nextOccurrenceDate,
    averageAmount: 825,
  });
};

export const createRecurringExpenses = (
  db: typeof database,
  userId: string,
  count: number = 1
) => {
  const expenses = shuffle(RECURRING_EXPENSES);

  return new Array(count).fill(0).map((_, index) => {
    const nextOccurrenceDate = dayjs().add(index * 3, "day");
    const lastOccurrenceDate = dayjs(nextOccurrenceDate).subtract(1, "month");
    const expense = expenses[index];

    return createRecurringExpense(db, {
      ...expense,
      userId,
      lastOccurrenceDate,
      nextOccurrenceDate,
    });
  });
};

export const createCardTransactions = (
  db: typeof database,
  userId: string,
  count: number = 1
) =>
  new Array(count).fill(0).map(() =>
    db.cardTransaction.create({
      userId,
      merchantName: getRandomMerchant(),
    })
  );

export const createShifts = (
  db: typeof database,
  userId: string,
  count: number = 1
) =>
  new Array(count).fill(0).map((_, index) =>
    db.shift.create({
      userId,
      earnings: [db.shiftEarning.create()],
      endDate: dayjs().subtract(index, "day").format(),
    })
  );

const createUser = (
  db: typeof database,
  {
    firstName,
    lastName,
    logo,
    ...userPartial
  }: {
    firstName?: string;
    lastName?: string;
    logo?: { data: string; type: string };
  }
) => {
  const email =
    firstName && lastName
      ? `${firstName.toLowerCase()}.${lastName.toLowerCase()}@example.com`
      : undefined;

  const partnerLogo =
    logo && logo.type.match(/^image\//) ? db.file.create(logo) : undefined;

  return db.user.create({
    firstName,
    lastName,
    email,
    currentAddress: db.address.create(),
    currentCardAccount: db.cardAccount.create(),
    partnerLogo,
    ...userPartial,
  });
};

export const seedUser = (db: typeof database, userPartial) => {
  const user = createUser(db, userPartial);

  createRecurringExpenses(db, user.id, 6);
  createRentExpense(db, user.id);
  createShifts(db, user.id, 3);
  createCardTransactions(db, user.id);
  db.paystub.create({ userId: user.id });

  return user;
};

const seed = (db: typeof database) => {
  seedUser(db, {
    firstName: "Val",
    lastName: "Clark",
  });
  seedUser(db, {
    firstName: "Casey",
    lastName: "Parker",
    partnerSlug: "msufcu",
  });
};

export default seed;
