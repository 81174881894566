import React from "react";
import { TimelineEventStepStatus } from "components/ui/Timeline";
import { ApiDashboardOverview } from "utils/api/dashboard";
import { ApiUserProfile, RepaymentMethod } from "utils/api/user";

const getRemainderTitle = (userProfile: ApiUserProfile) => {
  switch (userProfile?.repaymentMethod) {
    case RepaymentMethod.directDepositFlow:
      return "Remainder deposited";
    case RepaymentMethod.achDebitFlow:
    default:
      return "Net paycheck";
  }
};

export const getEvents = ({
  dashboard,
  userProfile,
}: {
  dashboard: ApiDashboardOverview;
  userProfile: ApiUserProfile;
}) => {
  if (!dashboard) return [];

  const { accounts } = dashboard;

  const payPeriodEndEvent = {
    data: () => ({
      title: "End of Pay Period",
      description:
        "Your earnings will continue to count toward your spending limit until payday.",
    }),
  };

  const paydayEvent = {
    data: () => ({
      title: "Payday 🎉",
      description: (
        <>
          Once we receive your paycheck, we'll forward the remainder to your
          linked checking account. Processing time depends on{" "}
          {accounts?.primaryBank || "your bank"}, but in most cases your deposit
          will arrive in a few hours.
        </>
      ),
      steps: [
        {
          status: TimelineEventStepStatus.incomplete,
          title: "Paycheck received",
          subtitle: accounts?.payroll || "Payroll provider",
        },
        {
          status: TimelineEventStepStatus.incomplete,
          title: "Advances repaid",
          subtitle: "Reset card",
        },
        {
          status: TimelineEventStepStatus.incomplete,
          title: getRemainderTitle(userProfile),
          subtitle: accounts?.primaryBank || "Checking account",
        },
      ],
    }),
  };

  return [payPeriodEndEvent, paydayEvent];
};
